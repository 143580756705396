import React from "react"
import "../assets/css/loading.scss"

export default () => {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  )
}
