import React, { useState, useEffect, useRef } from "react"
import Image from "gatsby-image"
import { getFluidImageObject } from "gatsby-transformer-cloudinary"
import Loading from "../components/loading"
//import { useIntersection } from "../effects/intersection.effect"
const Utils = {
  getURL: () => {},
}

const Config = {
  cloudName: "the-stil-trust",
  apiKey: 343477932942983,
  apiSecret: "fHytgDS9q5NQ1pMasMeehB64imU",
  directory: "site",
}

export const FluidImage = ({ src, alt, options = {} }) => {
  const [fluid, setFluid] = useState(false)
  let classList = []
  if (options.objectFit) {
    classList.push(options.objectFit)
  }

  // originalHeight: 3024,
  // originalWidth: 4032,

  let config = {
    cloudName: Config.cloudName,
    breakpoints: [200, 400, 600, 800],
    transformations: ["c_fill"],
    chained: [],
    maxWidth: 2000,
    public_id: "",
  }

  if (typeof src === "object") {
    let url = Utils.getURL(src, "fileName")
    if (url) {
      config.public_id = `${Config.directory}/${url}`
      if (src.metadata) {
        config.originalHeight = src.metadata.pixelHeight
        config.originalWidth = src.metadata.pixelWidth
      } else {
        config.originalHeight = 600
        config.originalWidth = 400
      }
    }
  } else {
    if (src) {
      config.public_id = `${Config.directory}/${src}`
    }
  }

  useEffect(() => {
    if (config.public_id) {
      getFluidImageObject(config).then(result => setFluid(result))
    }
  }, [config])

  return fluid ? (
    <Image fluid={fluid} alt={alt} className={classList.join(" ")} />
  ) : (
    <div className="image-loading">loading...</div>
  )
}

export const FixedImage = ({ src, alt, options = {} }) => {
  const [fixed, setFixed] = useState(false)

  const ref = useRef()
  //const isOnScreen = useIntersection(ref)

  let classList = []
  if (options.objectFit) {
    classList.push(options.objectFit)
  }

  let config = {
    cloudName: Config.cloudName,
    defaults: [],
    transformations: ["fl_progressive"],
    public_id: "",
  }
  if (src) {
    config.public_id = `${Config.directory}/${src}`
  }

  useEffect(() => {
    if (config.public_id) {
      if (ref.current) {
        config.width = ref.current.offsetWidth
        config.height = ref.current.offsetHeight
        if (config.width) {
          config.transformations.push(
            `w_${config.width < 1000 ? config.width * 2 : config.width}`
          )

          if (config.width < 1000 && options.face) {
            config.transformations.push(`c_crop`)
            config.transformations.push(`g_face`)
            config.transformations.push(`dpr_3.0`)
          }
        }
        getFluidImageObject(config).then(result => setFixed(result))
      }
    }
  }, [])

  return (
    <div
      className="image-holder"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={ref}
    >
      {fixed ? (
        <Image
          fluid={fixed}
          alt={alt}
          className={classList.join(" ")}
          style={{ maxWidth: "100%", width: "100%", height: "100%" }}
          imgStyle={{
            objectPosition: options.gravity
              ? options.gravity.replace("_", " ")
              : "top",
            objectFit: options.fit || "cover",
          }}
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}
