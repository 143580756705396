import React, { Fragment } from "react"
import PropTypes from "prop-types"
import "../assets/css/cta.scss"
import { Link } from "gatsby"

const renderLink = data => {
  if (data.link) {
    return (
      <Link to={`/${data.link ? data.link.url : ""}`}>
        <button>{data.buttonText || ""}</button>
      </Link>
    )
  } else if (data.externalLink) {
    return (
      <a href={data.externalLink} rel="noopener nofollow" target="_blank">
        <button>{data.buttonText || ""}</button>
      </a>
    )
  } else {
    return data.buttonText
  }
}

const FullCTA = ({ data, side, main }) => {
  let template = (
    <div>
      {data.buttonText && renderLink(data)}{" "}
      {data.secondaryCTA &&
        data.secondaryCTA.buttonText &&
        renderLink(data.secondaryCTA)}
    </div>
  )

  return (
    <section
      className={`cta full ${data.backgroundColor ? data.backgroundColor : ""}`}
    >
      {!data.bodyCaseText ? <h2>{data.title}</h2> : <h4>{data.title}</h4>}
      <div className="divider"></div>
      {data.text && (
        <div
          className="text talign-center"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      )}
      {template}
    </section>
  )
}

FullCTA.defaultProps = {
  data: {},
}

FullCTA.propTypes = {
  data: PropTypes.object,
}

export default FullCTA
